import * as React from "react"
import { Link } from "gatsby"
import { FeaturedProductsStyles } from "./FeaturesStyles"
import FeaturedProduct from "./FeaturedProduct"
import useFeaturedProduct from "../../hooks/use-featured-product"
import Button from "../Button/Button"

const Features = ({ title, introduction }) => {
  const featuredProduct = useFeaturedProduct()

  return (
    <FeaturedProductsStyles className="section">
      {title || introduction ? (
        <div className="container container__tight">
          <div className="intro__area">
            {title && <h2>{title}</h2>}
            {introduction && <p>{introduction}</p>}
          </div>
        </div>
      ) : null}

      <div className="container container__tight container__scroll">
        <div
          className="youtube"
          style={{ textAlign: "center", border: "solid yellow" }}
        >
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/G7UU5pYBJAM"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>

        {/* {featuredProduct.map((node, index) => {
          return <FeaturedProduct feature={node} key={index} />
        })} */}
      </div>
      {/* <div className="container container__tight learn__more">
        <Button as={Link} to="/products" text="All Products" />
      </div> */}
    </FeaturedProductsStyles>
  )
}

export default Features
